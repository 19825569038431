import React, { createContext, useContext, useState } from 'react';

interface FormContext {
  isContactOpen: boolean;
  setContactOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}
const ContactModalContext = createContext<FormContext>({
  isContactOpen: false,
});

const ContactModalProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [isContactOpen, setContactOpen] = useState(false);
  return (
    <ContactModalContext.Provider
      value={{
        setContactOpen,
        isContactOpen,
      }}
    >
      {children}
    </ContactModalContext.Provider>
  );
};

const useContactModalStateContext = () => {
  return useContext(ContactModalContext);
};

export { ContactModalProvider, useContactModalStateContext };
