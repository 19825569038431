exports.components = {
  "component---src-modules-template-brand-tsx": () => import("./../../../src/modules/template/brand.tsx" /* webpackChunkName: "component---src-modules-template-brand-tsx" */),
  "component---src-modules-template-index-tsx": () => import("./../../../src/modules/template/index.tsx" /* webpackChunkName: "component---src-modules-template-index-tsx" */),
  "component---src-modules-template-product-group-tsx": () => import("./../../../src/modules/template/product-group.tsx" /* webpackChunkName: "component---src-modules-template-product-group-tsx" */),
  "component---src-modules-template-product-tsx": () => import("./../../../src/modules/template/product.tsx" /* webpackChunkName: "component---src-modules-template-product-tsx" */),
  "component---src-modules-template-solutions-tsx": () => import("./../../../src/modules/template/solutions.tsx" /* webpackChunkName: "component---src-modules-template-solutions-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */)
}

